<template>
  <span>
    <h1 class="headline blue--text text-center mt-4 mb-3">Hyvitykset</h1>
    <v-divider></v-divider>
    Valitse päivämäärä:
    <v-row>
        <v-col cols="2">
            <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
        </v-col>
        <v-col cols="2">
            <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
        </v-col>
        <v-col cols="1">
            <v-btn
                  class="v-btn ma-3 green float-right"
                  @click="getResource"
                  small
            >
                HAE
            </v-btn>
        </v-col>
    </v-row>
    <template v-if="!resourceLoading">
      <v-card>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                    Asiakas
                </th>
                <th class="text-left">
                    Arvo
                </th>
                <th class="text-left">
                    Arvo nyt
                </th>
                <th class="text-left">
                    info
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in resource.vouchers"
                  :key="item.id"
              >
                <td>
                    {{ item.consumer_number }}
                </td>
                <td>
                    {{ item.initial_value }}
                </td>
                <td>
                    {{ item.value }}
                </td>
                <td>
                    Voimassa: {{ item.valid_from }}-{{ item.valid_to}}<br/>
                    Nimi: {{ item.name }}<br/>
                    Info: {{ item.text_on_bill }}<br/>
                    <template v-if="item.used_features && item.used_features.length > 0">
                        Käyttö:
                        <div v-for="feat in item.used_features" :key="feat.used_date">
                            {{ feat.used_date }}: {{ feat.used_value }}
                        </div>
                    </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-card class="mt-2">
        <v-card-text>
            <v-row>
                <v-col>
                    <b>Lisättyjä:</b><br/>
                    {{ resource.added_total }} €
                </v-col>
                <v-col>
                    <b>Käytettyjä:</b><br/>
                    {{ resource.used_total }} €
                </v-col>
                <v-col>
                    <b>Jäljellä:</b><br/>
                    {{ resource.left_total }} €
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-skeleton-loader v-else>
        Ladataan
    </v-skeleton-loader>
  </span>
</template>

<script>

export default {
    name: "CustomerVouchers",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            resourceLoading: false,
            resource: [],
        }
    },
    methods:{
        async getResource(){
            this.resourceLoading = true
            this.copied = false
            this.resource = []
            await this.axios.get('reports/vouchers', {
                params:{
                  from: this.startDate,
                  to: this.endDate,
                }
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
    },
    mounted(){
        this.startDate = this.$day().startOf('month').format("DD.MM.YYYY")
        this.endDate = this.$day().endOf('month').format("DD.MM.YYYY")
        this.getResource()
    }

}
</script>

<style scoped>

</style>